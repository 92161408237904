import { useEffect, useMemo, useRef, useState } from "react";

import { Box, Typography, Grid } from "@mui/material";

import {
  relayDistance,
  relaySubTimes,
  relaySubArray,
  relayResultText,
  getBirthYear,
} from "../../../../../components/utils";
import { determineParaCode } from "../../../../../components/utils/determineParaCodes";
import { getTakeOverTime } from "../../../../../components/utils/relaySubTimes";

export default function RelayHeatSubResults({
  lane,
  event,
  heatStatus,
  showAge,
}) {
  const dqSwimmer = lane?.dq_relay_swimmer;
  const distance = relayDistance(event);
  const relaySubResults = relaySubTimes(distance, event, lane);
  const tempRelayArr = relaySubArray(lane, relaySubResults);

  const animateIndexes = useRef([]);
  const [animateResult, setAnimateResult] = useState(0);
  const [relayArr, setRelayArr] = useState([]);

  useMemo(() => {
    if (tempRelayArr.length !== 0) {
      if (relayArr.length === 0) {
        setRelayArr(tempRelayArr);
      } else {
        if (
          tempRelayArr.find(
            (item, i) => JSON.stringify(item) !== JSON.stringify(relayArr[i])
          )
        ) {
          const newResults = tempRelayArr.map(
            (result, i) =>
              JSON.stringify(result) !== JSON.stringify(relayArr[i]) &&
              result?.competitor_split_values?.filter(
                (split) => split?.currentSplit === 0
              ).length !== 2 &&
              i
          );

          const indexes = newResults.filter((result) => result === 0 || result);
          animateIndexes.current = indexes;
          setRelayArr(tempRelayArr);
          setAnimateResult(1);
        }
      }
    }
  }, [tempRelayArr]);

  return (
    <Grid
      container
      item
      xs={12}
      pt={0.5}
      alignItems="center"
      sx={{ bgcolor: "#FFF" }}
    >
      {relayArr &&
        relayArr.map((result, index, arr) => {
          return (
            <Box
              container
              item
              key={result?.id + "" + index}
              width={"100%"}
              sx={{
                bgcolor: "",
              }}
            >
              <Grid
                className="heat-sub-result"
                onAnimationEnd={() => (
                  setAnimateResult(0), (animateIndexes.current = [])
                )}
                data-animationon={
                  animateIndexes.current.includes(index) ? animateResult : 0
                }
                container
                item
                key={result?.id + "" + index}
                py={0.25}
                ml="70px"
                mr={{ xs: "0px", md: "98px" }}
                sx={{
                  bgcolor: "",
                  width: "fit-content",
                  borderRadius: "4px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                  paddingTop: "1px",
                  paddingBottom: "1px",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  /* minWidth="80px"
              width="calc(100% - 358px)"
              maxWidth="175px" */
                  width="175px"
                  sx={{ bgcolor: "" }}
                >
                  <Typography
                    noWrap
                    fontSize="0.8rem"
                    lineHeight={"normal"}
                    sx={{ bgcolor: "" }}
                  >
                    {result?.competitor?.full_name}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  ml="8px"
                  width={40}
                  sx={{ bgcolor: "" }}
                >
                  <Typography
                    fontSize="0.8rem"
                    lineHeight={"normal"}
                    width={40}
                    sx={{ bgcolor: "" }}
                  >
                    {showAge
                      ? result?.competitor?.age
                      : getBirthYear(result?.competitor?.birthday)}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  ml="8px"
                  sx={{ bgcolor: "" }}
                >
                  {index === 0
                    ? result?.competitor_split_values && (
                        <>
                          <Typography
                            width="42px"
                            fontSize="0.8rem"
                            lineHeight={"normal"}
                            mx="4px"
                            sx={{ bgcolor: "" }}
                          >
                            {lane?.reaction_time && `(${lane?.reaction_time})`}
                          </Typography>
                          {result?.competitor_split_values?.map(
                            (split, splitIndex) => {
                              return (
                                <Typography
                                  key={split?.id}
                                  display="flex"
                                  justifyContent="flex-end"
                                  fontSize="0.8rem"
                                  lineHeight={"normal"}
                                  width={splitIndex === 0 ? 36 : 44}
                                  mx="4px"
                                  sx={{ bgcolor: "" }}
                                >
                                  {relayResultText(
                                    split?.currentSplit,
                                    index,
                                    heatStatus === 3 ? dqSwimmer : 0
                                  )}
                                </Typography>
                              );
                            }
                          )}
                        </>
                      )
                    : result?.competitor_split_values && (
                        <>
                          <Typography
                            width="42px"
                            fontSize="0.8rem"
                            lineHeight={"normal"}
                            mx="4px"
                            sx={{ bgcolor: "" }}
                          >
                            {getTakeOverTime(index, arr, heatStatus === 3)}
                          </Typography>
                          {result?.competitor_split_values?.map(
                            (split, splitIndex) => {
                              return (
                                <Typography
                                  key={split?.id}
                                  display="flex"
                                  justifyContent="flex-end"
                                  fontSize="0.8rem"
                                  lineHeight={"normal"}
                                  width={splitIndex === 0 ? 36 : 44}
                                  mx="4px"
                                  sx={{ bgcolor: "" }}
                                >
                                  {relayResultText(
                                    split?.currentSplit,
                                    index,
                                    heatStatus === 3 ? dqSwimmer : 0
                                  )}
                                </Typography>
                              );
                            }
                          )}
                        </>
                      )}
                </Box>
                {result?.sub_results[result?.sub_results?.length - 1] &&
                result?.sub_results[result?.sub_results?.length - 1]
                  .result_value ? (
                  relayResultText(
                    result?.sub_results[result?.sub_results?.length - 1]
                      .result_value,
                    index,
                    heatStatus === 3 ? dqSwimmer : 0
                  ) && (
                    <Typography
                      display="flex"
                      justifyContent="flex-end"
                      mx="4px"
                      fontSize="0.8rem"
                      lineHeight={"normal"}
                      width={44}
                      sx={{ bgcolor: "" }}
                    >
                      {heatStatus === 3 && lane?.dsq && index + 1 === dqSwimmer
                        ? "DSQ"
                        : relayResultText(
                            result?.sub_results[result?.sub_results?.length - 1]
                              .result_value,
                            index,
                            dqSwimmer
                          )}
                    </Typography>
                  )
                ) : (
                  <Typography
                    display="flex"
                    justifyContent="flex-end"
                    mx="4px"
                    fontSize="0.8rem"
                    lineHeight={"normal"}
                    width={44}
                    sx={{ bgcolor: "" }}
                  >
                    {" "}
                  </Typography>
                )}
                {event.event_type &&
                  lane.competitor &&
                  determineParaCode(event.event_type, lane.competitor) && (
                    <Typography fontSize={"0.7em"}>
                      {determineParaCode(event.event_type, lane.competitor)}
                    </Typography>
                  )}
              </Grid>
            </Box>
          );
        })}
    </Grid>
  );
}
