export default function relaySubTimes(distance, event_distance, lane) {
  let arr = [];
  for (let i = distance; i <= event_distance; i = i + distance) {
    arr.push(lane?.sub_results.find((result) => result?.done_at === i));
  }
  return arr;
}

export const getTakeOverTime = (index, arr, isOfficial) => {
  return isOfficial &&
    arr[index - 1]?.sub_results?.length &&
    arr[index - 1].sub_results[arr[index - 1]?.sub_results?.length - 1]
      ?.take_over
    ? `(${
        arr[index - 1]?.sub_results?.length &&
        arr[index - 1].sub_results[arr[index - 1]?.sub_results?.length - 1]
          ?.take_over
      })`
    : "";
};
