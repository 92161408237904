import { Button } from "@mui/material";
import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Oops, there was an issue!</h2>
          <p>{this.props.message}. Please try again or come back later.</p>
          <p>
            If this error persists, please contact our support so we can fix the
            problem.
          </p>
          <Button
            variant="grey"
            sx={{ fontSize: "0.75rem" }}
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </Button>
          <Button
            variant="grey"
            sx={{ fontSize: "0.75rem" }}
            style={{ marginLeft: "10px" }}
            onClick={() =>
              window.open("https://support.swimify.com/", "_blank")
            }
          >
            Contact Swimify Support
          </Button>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
