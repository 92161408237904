import { useEffect, useMemo, useRef, useState } from "react";

import { Box, Typography, Grid } from "@mui/material";

import {
  relayDistance,
  relaySubArray,
  relayResultText,
  getBirthYear,
  relaySubTimes,
} from "../../../../../components/utils";
import { getTakeOverTime } from "../../../../../components/utils/relaySubTimes";

type RelayArray = {
  competitor: Competitor;
  competitor_split_values: CompetitorSplitValue[];
  result_value: number;
  id: number;
  sub_results: SubResult[];
  take_over?: string;
};
type CompetitorSplitValue = {
  id: number;
  currentSplit: number;
};
export default function RelaySummarySubResults({
  lane,
  event,
  showAge,
  rank,
  round,
  eventLevel,
  initialRender,
  roundStatus,
  isOfficial = false,
}: {
  lane: Lane | undefined;
  event: Event;
  showAge: boolean | undefined;
  rank: number | undefined;
  round: number | undefined;
  eventLevel: number;
  initialRender: boolean;
  roundStatus: number | undefined;
  isOfficial: boolean;
}) {
  const dqSwimmer = lane?.dq_relay_swimmer;

  const [tempRelayArr, setTempRelayArr] = useState<RelayArray[]>([]);
  const animateIndexes = useRef([]);
  const [animateResult, setAnimateResult] = useState(0);
  const [relayArr, setRelayArr] = useState<RelayArray[]>([]);

  useMemo(() => {
    if (tempRelayArr.length > 0) {
      if (relayArr.length === 0) {
        setRelayArr(tempRelayArr);
      } else {
        if (
          tempRelayArr.find(
            (item, i) => JSON.stringify(item) !== JSON.stringify(relayArr[i])
          )
        ) {
          const newResults = tempRelayArr.map(
            (result, i) =>
              JSON.stringify(result) !== JSON.stringify(relayArr[i]) &&
              result?.competitor_split_values?.filter(
                (split) => split?.currentSplit === 0
              ).length !== 2 &&
              i
          );
          const indexes = newResults.filter((result) => result === 0 || result);
          //@ts-ignore
          animateIndexes.current = indexes;
          setRelayArr(tempRelayArr);
          setAnimateResult(1);
        }
      }
    }
  }, [tempRelayArr]);

  useEffect(() => {
    const distance = relayDistance(event);
    const relaySubResults = relaySubTimes(distance, event.distance, lane);
    const tempRelayArr1 = relaySubArray(lane, relaySubResults);
    setTempRelayArr(tempRelayArr1);
  }, []);
  return (
    <Grid
      container
      item
      xs={12}
      pt={0.5}
      alignItems="center"
      sx={{
        bgcolor:
          eventLevel <= 1 && (round === 0 || round === 2) && isOfficial
            ? rank === 1
              ? "rgba(235, 195, 22, 1)"
              : rank === 2
              ? "rgba(211, 211, 211, 1)"
              : rank === 3
              ? "#BF6F3D"
              : "#FFF"
            : "#FFF",
      }}
    >
      {relayArr?.map((result, index, arr) => {
        return (
          // @ts-ignore
          <Box
            container
            item
            key={result?.id + "" + index}
            width={"100%"}
            sx={{
              bgcolor: "",
            }}
          >
            <Grid
              className="heat-sub-result"
              onAnimationEnd={() => (
                setAnimateResult(0), (animateIndexes.current = [])
              )}
              data-animationon={
                // @ts-ignore
                animateIndexes.current.includes(index) ? animateResult : 0
              }
              container
              item
              key={result?.id + "" + index}
              py={0.25}
              ml="70px"
              mr={{ xs: "0px", md: "98px" }}
              sx={{
                bgcolor: "",
                width: "fit-content",
                borderRadius: "4px",
                paddingLeft: "4px",
                paddingRight: "4px",
                paddingTop: "1px",
                paddingBottom: "1px",
              }}
            >
              <Box
                display="flex"
                justifyContent="flex-start"
                width="175px"
                sx={{ bgcolor: "" }}
              >
                <Typography noWrap fontSize="0.8rem" lineHeight={"normal"}>
                  {result?.competitor?.full_name}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                ml="8px"
                width={40}
                sx={{ bgcolor: "" }}
              >
                <Typography fontSize="0.8rem" lineHeight={"normal"}>
                  {showAge
                    ? result?.competitor?.age
                    : getBirthYear(result?.competitor?.birthday)}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                ml="8px"
                sx={{ bgcolor: "" }}
              >
                <Typography
                  width="42px"
                  fontSize="0.8rem"
                  lineHeight={"normal"}
                  mx="4px"
                  sx={{ bgcolor: "" }}
                >
                  {index === 0
                    ? lane?.reaction_time && `(${lane?.reaction_time})`
                    : getTakeOverTime(index, arr, roundStatus === 5)}
                </Typography>
                {result?.competitor_split_values?.map((split, splitIndex) => {
                  return (
                    <Typography
                      key={split?.id}
                      display="flex"
                      justifyContent="flex-end"
                      fontSize="0.8rem"
                      lineHeight={"normal"}
                      width={splitIndex === 0 ? 36 : 44}
                      mx="4px"
                      sx={{ bgcolor: "" }}
                    >
                      {relayResultText(split?.currentSplit, index, dqSwimmer)}
                    </Typography>
                  );
                })}
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                width={44}
                ml="8px"
                sx={{ bgcolor: "" }}
              >
                {result?.sub_results[result?.sub_results?.length - 1] &&
                result?.sub_results[result?.sub_results?.length - 1]
                  .result_value ? (
                  <Typography
                    display="flex"
                    justifyContent="flex-end"
                    mx="4px"
                    fontSize="0.8rem"
                    lineHeight={"normal"}
                    width={44}
                    sx={{ bgcolor: "" }}
                  >
                    {lane?.dsq && index + 1 === dqSwimmer
                      ? "DSQ"
                      : relayResultText(
                          result?.sub_results[result?.sub_results?.length - 1]
                            .result_value,
                          index,
                          dqSwimmer
                        )}
                  </Typography>
                ) : (
                  <Typography
                    display="flex"
                    justifyContent="flex-end"
                    mx="4px"
                    fontSize="0.8rem"
                    lineHeight={"normal"}
                    width={44}
                    sx={{ bgcolor: "" }}
                  >
                    {" "}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Box>
        );
      })}
    </Grid>
  );
}
